/* eslint-disable jsx-a11y/anchor-is-valid */
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React, { useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import Layout from '../components/Layout'
import TransactionCreate from '../components/TransactionCreate'
import TransactionItem from '../components/TransactionItem'
import Button from '../components/common/Button'
import { useModal } from '../hooks/modal'
import { useLoad } from '../hooks/request'
import { TRANSACTION_CATEGORY_LIST, TRANSACTION_LIST, WALLET_DETAIL } from '../urls'
import Table from '../components/common/Table'
import { useQueryParams } from '../hooks/queryString'
import { getMonth } from '../utils/date'
import { checkPermission as cp } from '../utils/auth'
import { PermissionDenied } from '../components/PermissionDenied'
import { format } from '../utils/number'
import TransactionTransfer from '../components/TransactionTransfer'
import SearchFilter from '../components/common/SearchFilter'
import FilterSelect from '../components/common/FilterSelect'
import DateFilter from '../components/common/DateFilter'

export default function Transaction({ history }) {
    const params = useQueryParams()
    const [showDropdown, setShowDropdown] = useState(false)
    const { walletId } = useParams()
    const [page, setPage] = useState(1)
    const wallet = useLoad({ url: WALLET_DETAIL.replace('{id}', walletId), params: { ...params } })

    const branch = useLoad({ url: TRANSACTION_CATEGORY_LIST })
    const branchItems = branch.response ? branch.response.results : []

    const transactions = useLoad({
        url: TRANSACTION_LIST,
        params: { ...params, page, wallet: walletId },
    }, [page])

    function reload() {
        transactions.request()
        wallet.request()
    }

    const [showCreateOutcomeModal, hideCreateOutcomeModal] = useModal(
        <TransactionCreate type="outcome" wallet={walletId} onCancel={() => hideCreateOutcomeModal()} onSuccess={() => {
            hideCreateOutcomeModal()
            reload()
        }} />,
    )

    const [showCreateTransferModal, hideCreateTransferModal] = useModal(
        <TransactionTransfer
            type="outcome"
            wallet={wallet.response}
            onCancel={() => hideCreateTransferModal()}
            onSuccess={() => {
                hideCreateOutcomeModal()
                reload()
            }} />,
    )

    // Create new Transaction Modal
    const [showCreateIncomeModal, hideCreateIncomeModal] = useModal(
        <TransactionCreate type="income" wallet={walletId} onCancel={() => hideCreateIncomeModal()} onSuccess={() => {
            hideCreateIncomeModal()
            reload()
        }} />,
    )

    if (!cp('finance.view_transaction')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <header
                style={{ display: 'flex', alignItems: 'center', columnGap: '4rem', flexWrap: 'wrap', rowGap: '1rem' }}>
                <p className="is-size-4">
                    <NavLink className="has-text-link button is-white" to="/finance/wallet">
                        <i className="icon ion-md-arrow-back is-size-4" />
                    </NavLink> &nbsp;

                    {wallet.response ? wallet.response.name : null}
                </p>

                <main style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    flex: 'auto',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-end',
                }}>
                    <SearchFilter />
                    <FilterSelect name="category" options={branchItems} placeholder="Категория" />
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <DateFilter name="start_date" />
                        <DateFilter name="end_date" />
                    </div>

                    <div>
                        {cp('finance.add_transaction') ? (
                            <Button
                                text="Добавить расход"
                                className="is-outlined is-link"
                                icon="ion-md-add"
                                onClick={showCreateOutcomeModal} />
                        ) : null}

                        <span className={cn('dropdown is-right', { 'is-active': showDropdown })}
                            onMouseLeave={() => setShowDropdown(false)}>

                            <Button
                                className="button dropdown-trigger is-outlined is-link"
                                icon="ion-md-arrow-dropdown"
                                onClick={() => setShowDropdown(!showDropdown)} />

                            <span className="dropdown-menu">
                                <span className="dropdown-content is-pulled-left">
                                    {cp('finance.add_transaction') ? (
                                        <a className="dropdown-item" onClick={showCreateIncomeModal}>
                                            <i className="icon ion-md-add" />
                                            Добавить приход
                                        </a>
                                    ) : null}

                                    {cp('finance.add_transaction') ? (
                                        <a className="dropdown-item" onClick={showCreateTransferModal}>
                                            <i className="icon ion-md-swap" />
                                            Перевести на другой счет
                                        </a>
                                    ) : null}
                                </span>
                            </span>
                        </span>
                    </div>
                </main>
            </header>

            { !transactions.loading && !wallet.loading && wallet.response ? (
                <div className={cn(css(styles.columns), 'columns is-multiline')}>
                    <div className="is-mobile column">
                        Общая сумма

                        <p className="is-size-4">
                            {format(wallet.response.balance ? wallet.response.balance : 0)}
                        </p>
                    </div>

                    <div className="is-mobile column">
                        Приход({getMonth()})

                        <p className="is-size-4 has-text-success">
                            {format(wallet.response.income ? wallet.response.income : 0)}
                        </p>
                    </div>

                    <div className="is-mobile column">
                        Расход({getMonth()})

                        <p className="is-size-4 has-text-danger">
                            {format(wallet.response.outcome ? wallet.response.outcome : 0)}
                        </p>
                    </div>
                </div>
            ) : null }

            <Table
                activePage={page}
                onPageChange={setPage}
                loading={transactions.loading || wallet.loading}
                totalCount={transactions.response ? transactions.response.count : 0}
                columns={{
                    description: 'Описание',
                    amount: 'Сумма',
                    createdBy: 'Кем создана',
                    category: 'Категория',
                    createdAt: 'Дата создания',
                    actions: '',
                }}
                items={transactions.response ? transactions.response.results : []}
                renderItem={(transaction) => (
                    <TransactionItem
                        walletId={walletId}
                        reload={reload}
                        transaction={transaction}
                        key={transaction.id} />
                )} />
        </Layout>
    )
}

const styles = StyleSheet.create({
    columns: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '1.2rem 0',
    },
})
